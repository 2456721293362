exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-calendar-tsx": () => import("./../../../src/pages/calendar.tsx" /* webpackChunkName: "component---src-pages-calendar-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-channel-manager-tsx": () => import("./../../../src/pages/channelManager.tsx" /* webpackChunkName: "component---src-pages-channel-manager-tsx" */),
  "component---src-pages-glossary-tsx": () => import("./../../../src/pages/glossary.tsx" /* webpackChunkName: "component---src-pages-glossary-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-cookie-policy-tsx": () => import("./../../../src/pages/legal/cookiePolicy.tsx" /* webpackChunkName: "component---src-pages-legal-cookie-policy-tsx" */),
  "component---src-pages-legal-data-protection-tsx": () => import("./../../../src/pages/legal/dataProtection.tsx" /* webpackChunkName: "component---src-pages-legal-data-protection-tsx" */),
  "component---src-pages-legal-privacy-notice-tsx": () => import("./../../../src/pages/legal/privacyNotice.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-notice-tsx" */),
  "component---src-pages-legal-terms-and-conditions-tsx": () => import("./../../../src/pages/legal/termsAndConditions.tsx" /* webpackChunkName: "component---src-pages-legal-terms-and-conditions-tsx" */),
  "component---src-pages-message-features-tsx": () => import("./../../../src/pages/messageFeatures.tsx" /* webpackChunkName: "component---src-pages-message-features-tsx" */),
  "component---src-pages-platform-tsx": () => import("./../../../src/pages/platform.tsx" /* webpackChunkName: "component---src-pages-platform-tsx" */),
  "component---src-pages-price-sync-tsx": () => import("./../../../src/pages/priceSync.tsx" /* webpackChunkName: "component---src-pages-price-sync-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-property-setup-tsx": () => import("./../../../src/pages/propertySetup.tsx" /* webpackChunkName: "component---src-pages-property-setup-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-solution-tsx": () => import("./../../../src/pages/solution.tsx" /* webpackChunkName: "component---src-pages-solution-tsx" */),
  "component---src-pages-statistics-tsx": () => import("./../../../src/pages/statistics.tsx" /* webpackChunkName: "component---src-pages-statistics-tsx" */),
  "component---src-pages-unified-inbox-tsx": () => import("./../../../src/pages/unifiedInbox.tsx" /* webpackChunkName: "component---src-pages-unified-inbox-tsx" */),
  "component---src-pages-users-and-roles-tsx": () => import("./../../../src/pages/usersAndRoles.tsx" /* webpackChunkName: "component---src-pages-users-and-roles-tsx" */),
  "component---src-pages-web-site-tsx": () => import("./../../../src/pages/webSite.tsx" /* webpackChunkName: "component---src-pages-web-site-tsx" */)
}

